<template>
  <section>
    <v-btn
      depressed
      color="primary"
      outlined
      class="mr-2"
      @click="showDialog"
    >
      {{$_strings.order.CHECK_ITEMS}}
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary mr-2"
          dark
          :loading="uploadingCsv"
          v-bind="attrs"
          v-on="on"
        >
          Upload
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-btn
              text
              block
              :loading="downloadingCsv"
              @click="downloadTemplate"
            >
              Download Template
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn
              text
              block
              :loading="uploadingCsv"
              @click="$refs.fileInput.click();"
            >
              {{ $_strings.order.UPLOAD_ITEMS }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <input
          style="display: none;"
          id="csv-upload"
          :disabled="uploadingCsv"
          ref="fileInput"
          type="file"
          accept=".csv"
          @change="uploadCsv"
        />
      </v-list>
    </v-menu>
    <DialogUploadOrder
      ref="dialogUploadOrder"
      :shipmentId="shipment.id"
    />
  </section>
</template>

<script>
const DialogUploadOrder = () => import(/* webpackChunkName: "DialogUploadOrder" */ '../Dialog/UploadOrder');
const { exportCSVFile } = require('@/helper/csvGenerator');

export default {
  name: 'btn-upload-order',
  components: {
    DialogUploadOrder,
  },
  props: {
    shipment: Object,
  },
  data() {
    return {
      downloadingCsv: false,
      uploadingCsv: false,
    };
  },
  methods: {
    showDialog(e) {
      this.$refs.dialogUploadOrder.dialog = true;
      e.stopPropagation();
    },
    async downloadTemplate() {
      try {
        this.downloadingCsv = true;
        const result = await this.$_services.order.templateShipmentDetailCSV();
        const { column, filename } = result.data;
        exportCSVFile(null, [[...column]], filename);
      } finally {
        this.downloadingCsv = false;
      }
    },
    async uploadCsv() {
      const file = this.$refs.fileInput.files[0];
      const typeSupport = ['text/csv'];
      if (!typeSupport.includes(file.type)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return;
      }
      try {
        const shipmentId = this.shipment.id;
        this.uploadingCsv = true;
        const formData = new FormData();
        formData.append('file', file);
        await this.$_services.order.uploadOrders({ shipmentId, formData });
        this.$dialog.notify.success('Berhasil mengunggah');
        this.$emit('fetchData');
      } finally {
        const input = this.$refs.fileInput;
        input.type = 'text';
        input.type = 'file';
        this.uploadingCsv = false;
      }
    },
  },
};
</script>
